import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    this.hideListOnClickOutside()
  }

  toggleList() {
    this.element.classList.toggle('is-open')
  }

  hideListOnClickOutside() {
    document.addEventListener('click', (e) => {
      if (!this.element.contains(e.target)) {
        this.element.classList.remove('is-open')
      }
    })
  }
}
