import { Controller } from 'stimulus'

export default class extends Controller {

  connect() {
    this.checkPositionNavigation()
  }

  disconnect() {
    if (this.scrollFrame) {
      cancelAnimationFrame(this.scrollFrame)
    }
  }

  checkPositionNavigation() {
    const scroll = () => {
      if (window.scrollY > 0) {
        this.element.classList.remove('is-home')
      } else {
        this.element.classList.add('is-home')
      }
      this.scrollFrame = requestAnimationFrame(scroll)
    }

    this.scrollFrame = requestAnimationFrame(scroll)
  }
}
