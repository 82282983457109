import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['menu']

  handleBurgerMenuAndNavigation(e) {
    e.currentTarget.classList.toggle('is-open')
    this.menuTarget.classList.toggle('is-visible')
  }
}
