import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    this.handleFlashApparition()
  }

  handleFlashApparition() {
    this.element.classList.add('is-visible')
    setTimeout(() => {
      this.element.classList.remove('is-visible')
    }, 5000);
  }
}
