import { Controller } from '@hotwired/stimulus'
/**
 * Stimulus integration with Rails UJS. Use this controller to handle HTML
 * rendered responses from rails controllers.
 * @extends Controller
*/

export default class extends Controller {
  prepend(event) {
    const [, , xhr] = event.detail
    let responseHtml = this.element.querySelector('.form-login-error')

    if (!responseHtml) {
      responseHtml = document.createElement('p')
      responseHtml.classList.add('form-login-error')
      this.element.prepend(responseHtml)
    }

    responseHtml.innerHTML = xhr.responseText
  }
}
