import { Controller } from 'stimulus'
import { patch } from 'scripts/utils/ajax'

export default class extends Controller {
  static targets = ['iframeContainer']
  static values = { watchUrl: String }

  connect() {
    if (this.hasIframeContainerTarget) {
      this.createVimeoPlayer()
    }
  }

  createVimeoPlayer() {
    const iframe = this.iframeContainerTarget.querySelector('iframe')
    this.player = new Vimeo.Player(iframe)

    if (this.hasWatchUrlValue) {
      this.player.on('timeupdate', (evt) => {
        this.watch(evt)
      })
    }
  }

  async watch(data) {
    patch(this.watchUrlValue, data)
  }
}
