import { Controller } from 'stimulus'
import Swiper, { Navigation } from 'swiper'
Swiper.use([Navigation])

export default class extends Controller {
  static targets = ['slider', 'prev', 'next']

  connect() {
    this.initSlider()
  }

  initSlider() {
    const swiper = new Swiper(this.sliderTarget, {
      slidesPerView: 1,
      spaceBetween: 8,
      preventClicks: false,
      preventClicksPropagation: false,
      navigation: {
        nextEl: this.nextTarget,
        prevEl: this.prevTarget,
      },
      breakpoints: {
        768: {
          slidesPerView: 2,
        },
        1025: {
          slidesPerView: 'auto',
          spaceBetween: 20,
        }
      },
    });
  }
}
