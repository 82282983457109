import { Controller } from 'stimulus'
import tippy from 'tippy.js';

export default class extends Controller {

  connect() {
    this.handleTooltip()
  }

  handleTooltip() {
    const text = this.element.dataset.text
    tippy(this.element, {
      content: text,
      theme: 'zed',
    });
  }

  disableLink(e) {
    e.preventDefault()
  }
}
